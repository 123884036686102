import { FoodProgramTierEnum } from '@wonderschool/common-base-types';
import { Button, Input, Option, Select } from '@wonderschool/common-base-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { firestore } from '../../api/firebase/firebase';
import { ShowErrors } from '../../Components/Messages';
import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';
import { organizationUpdateStudent } from '../studentsRedux';

type StudentProgramsFormProps = {
  student: any;
  onClose: () => void;
  showPrograms?: 'foodProgram' | 'kinderConnect';
};

type ProgramsFormDataType = {
  id?: string;
  foodProgramTierObj?: Option;
  kinderConnectId?: number;
};

const foodProgramTierOptions = [
  { label: 'food.tierDropdown.NoTier', value: FoodProgramTierEnum.TIER_0.toString() },
  { label: 'food.tierDropdown.Tier1', value: FoodProgramTierEnum.TIER_1.toString() },
  { label: 'food.tierDropdown.Tier2', value: FoodProgramTierEnum.TIER_2.toString() },
];

export default function StudentProgramsForm({ student, onClose, showPrograms }: StudentProgramsFormProps) {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const { currentUser } = useAuthUser();
  const { foodProgramFlag } = useFlags();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ProgramsFormDataType>();

  useEffect(() => {
    if (!student) return;
    const foundOption = foodProgramTierOptions.find((option) => option.value === student.foodProgramTier?.toString());

    setValue('id', student.id);
    setValue(
      'foodProgramTierObj',
      foundOption
        ? { ...foundOption, label: t(foundOption.label) }
        : { ...foodProgramTierOptions[0], label: t('food.tierDropdown.NoTier') }
    );
    setValue('kinderConnectId', student?.kinderSystems?.kinderConnectId);
  }, [student, t, setValue]);

  const onSubmit = async (data: ProgramsFormDataType) => {
    const { id, foodProgramTierObj, kinderConnectId } = data;
    const foodProgramTier = Number(foodProgramTierObj?.value);
    if (currentOrganization && currentOrganization.id) {
      if (id) {
        try {
          const updateData: any = {
            id,
            combinedFamily: null,
            organization: currentOrganization.id,
          };

          if (!showPrograms || showPrograms === 'foodProgram') {
            updateData.foodProgramTier = foodProgramTier;
          }

          if (!showPrograms || showPrograms === 'kinderConnect') {
            updateData.kinderSystems = {
              kinderConnectId: Number(kinderConnectId),
              updatedBy: currentUser?.displayName,
              updatedAt: firestore.FieldValue.serverTimestamp(),
            };
          }

          dispatch(organizationUpdateStudent(currentOrganization.id, updateData));
          onClose();
        } catch (error: any) {
          console.error('Unable to Update:', error.message);
        }
      } else {
        console.error('No student id found to update.');
      }
    }
  };

  const renderFoodProgramDropdown = () => {
    const options: Option[] = foodProgramTierOptions.map((option) => ({
      label: t(option.label),
      value: option.value.toString(),
    }));

    return (
      <Controller
        name="foodProgramTierObj"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            data-testid="food-program-tier"
            id="foodProgramTier"
            label={t('food.tierDropdownLabel')}
            placeholder={t('food.tierDropdownPlaceholder')}
            options={options}
            extraClasses="z-auto"
          />
        )}
      />
    );
  };

  return (
    <div className="text-left">
      <ShowErrors errors={errors} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${isSubmitting ? 'animate-pulse' : ''}`}
        noValidate
        data-testid="student-programs-form"
      >
        {foodProgramFlag && (!showPrograms || showPrograms === 'foodProgram') && (
          <div className="flex w-full mb-4">
            <div className="w-2/3">{renderFoodProgramDropdown()}</div>
          </div>
        )}

        {currentOrganization?.isKinderSystemsEnabled && (!showPrograms || showPrograms === 'kinderConnect') && (
          <div className="flex w-full mb-4">
            <div className="w-2/3">
              <Controller
                name="kinderConnectId"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="kinderConnectId"
                    inputType="number"
                    label={t('KinderConnect ID')}
                    placeholder={t('KinderConnect ID')}
                    data-testid="kinder-connect-id"
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="flex space-x-4">
          <Button
            primary
            label={t('Save')}
            disabled={isSubmitting}
            loading={isSubmitting}
            data-testid="programs-edit-form-save-btn"
            type="submit"
          />

          <Button
            label={t('common.cancel')}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            data-testid="programs-edit-form-cancel-btn"
          />
        </div>
      </form>
    </div>
  );
}
