import { firestore } from '../api/firebase/firebase';
import { batchSaveDocuments, saveDocument } from '../api/firebase/firestore';
import { logError } from '../rollbar';
import { EnrollmentStatusEnum } from './enums';

const paths = {
  rootStudents: () => `students`,
  students: (organizationId: string) => `organizations/${organizationId}/students`,
  contacts: (organizationId: string) => `organizations/${organizationId}/contacts`,
};

export async function saveStudentAndContacts({
  organization,
  student,
  contacts,
}: {
  organization: any;
  student: any;
  contacts: any[];
}) {
  const batch = firestore().batch() as any;

  try {
    await saveDocument({ collectionPath: paths.students(organization.id), data: student, batch });

    for (const contact of contacts) {
      await saveDocument({ collectionPath: paths.contacts(organization.id), data: contact, batch });
    }
    await batch.commit();
  } catch (error) {
    logError('Error saving student and contacts', { studentId: student.id, error });
  }
}

type DocumentsReturnType = {
  count: number;
  list: any[];
  lastDocument: any | null;
  hasMoreData: boolean;
};

type FetchStudentsToEnrollParams = {
  organizationId?: string;
  targetDate?: Date;
};

const _empty_docs = {
  count: 0,
  list: [],
  lastDocument: null,
  hasMoreData: false,
};

export async function fetchStudentsToEnroll({
  organizationId: _orgId,
  targetDate = new Date(),
}: FetchStudentsToEnrollParams): Promise<DocumentsReturnType> {
  const snapshot = await firestore()
    .collection(paths.rootStudents())
    .where('enrollment.status', '==', EnrollmentStatusEnum.PENDING)
    .where('enrollment.automaticallyEnroll', '==', true)
    .where('enrollment.startDate', '<=', targetDate)
    .get();
  return {
    count: snapshot.size,
    list: snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
    lastDocument: snapshot.docs[snapshot.docs.length - 1],
    hasMoreData: false,
  } as DocumentsReturnType;
}

export async function enrollStudentsNow(students: any[], user: any) {
  try {
    const now = new Date();
    const dataToSave = students.map((student) => {
      return {
        id: student.id,
        organization: student.organization,
        enrollmentStatus: true,
        enrollmentDate: now.valueOf(),
        enrollment: {
          status: EnrollmentStatusEnum.ENROLLED,
          enrolled: {
            date: now,
            initiatedBy: user.uid ?? user.id ?? 'unknown',
            notes: 'Automatically enrolled',
          },
        },
      };
    });
    await batchSaveDocuments({
      documents: dataToSave,
      collectionPath: (student: any) => {
        return paths.students(student.organization);
      },
    });
  } catch (error) {
    logError('Error saving students', { error });
  }
}
